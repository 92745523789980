import Vue from 'vue'
import Router from 'vue-router'

import MobilityLayout from '@/layouts/MobilityLayout'

import ErrorPage from '@/pages/Error/Error'
import ErrorUnauthorized from '@/pages/Error/ErrorUnauthorized'
// import MobilityPage from '@/pages/Mobility/Mobility'

import store from './store'
import Auth from '@/pages/Auth/Auth'
import LoginForm from '@/pages/Auth/Login/Login'
import ForgotPassword from '@/pages/Auth/ForgotPassword/ForgotPassword'
import ActivateAccount from '@/pages/Auth/ActivateAccount/ActivateAccount'
import ResetPassword from '@/pages/Auth/ResetPassword/ResetPassword'
import CompletePassword from '@/pages/Auth/CompletePassword/CompletePassword'
// import MySettings from '@/pages/MySettings/MySettings'

Vue.use(Router)

export const loginPath = '/login'

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MobilityLayout,
      redirect: loginPath
      // children: [
      //   {
      //     path: '',
      //     name: 'MobilityPage',
      //     component: MobilityPage
      //   },
      //   {
      //     path: '/my-settings',
      //     name: 'MySettings',
      //     component: MySettings
      //   }
      // ]
    },
    {
      path: '/auth',
      component: Auth,
      redirect: loginPath,
      children: [
        {
          path: loginPath,
          component: LoginForm
        },
        {
          path: '/activate-account', // seems to be not in use
          component: ActivateAccount
        },
        {
          path: '/forgot-password',
          component: ForgotPassword
        },
        {
          path: '/reset-password',
          component: ResetPassword
        },
        {
          path: '/complete-password',
          component: CompletePassword,
          beforeEnter: (to, from, next) => {
            if (!store.state.auth.cognitoUser) {
              next(loginPath)
            } else {
              next()
            }
          }
        }
      ]
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage
    },
    {
      path: '/unauthorized',
      name: 'ErrorUnauthorized',
      component: ErrorUnauthorized
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

const isUserAuthenticated = () => !!store.state.auth.token

router.beforeEach((to, from, next) => {
  const publicRoutes = ['login', 'activate-account', 'forgot-password', 'reset-password', 'complete-password', 'error', 'unauthorized']
  if (publicRoutes.some(route => to.path.includes(route))) {
    if (isUserAuthenticated()) {
      next('/')
    } else {
      next()
    }
  } else {
    if (isUserAuthenticated()) {
      next()
    } else {
      next(loginPath)
    }
  }
})

export default router
